import "@/styles/globals.css";
import Head from 'next/head';



export default function App({ Component, pageProps }) {
	
	  
  return ( 
  <>
  <Head>
  <title>Nercast</title>
    <meta name="viewport" content="width=device-width, initial-scale=0.8, maximum-scale=0.8, minimum-scale=0.8, user-scalable=no" />
  </Head>
  <Component {...pageProps} />
</>
);
}
